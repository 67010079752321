import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
        <img src={avatar} className="image" alt=""  width={90} height={160}/>
      <h1>
          Monica Meya Ning Ying
      </h1>
        <h1>
            Project Manager and Interior Designer
        </h1>
    </div>
    <Footer />
  </header>
)

export default Header
