import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="#" className="icon fa-facebook">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="#" className="icon fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/monica-meya-ny-4627b91aa/" className="icon fa-linkedin">
            <span className="label">Linkedin</span>
          </a>
        </li>
      </ul>
      {/*<ul className="copyright">*/}
        {/*<li>&copy; Gatsby Starter Strata</li>*/}
        {/*<li>*/}
          {/*Design: <a href="http://html5up.net">HTML5 UP</a>*/}
        {/*</li>*/}
      {/*</ul>*/}
    </div>
  </div>
)

export default Footer
